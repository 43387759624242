import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  margin-top: 3rem;
`;

const CallToAction = ({ children }) => <Container>{children}</Container>;

CallToAction.propTypes = {
  children: PropTypes.string.isRequired,
};

export default CallToAction;
